import React, { useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import axiosInstance from "../../lib/apiconfig";

export default function AddUserAccount() {
  const [isOpen, setIsOpen] = useState(false);
  const [inviteData, setInviteData] = useState({
    name: "",
    email: "",
    designation: "",
  });

  const sendInvite = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("create/invite/", inviteData);
      if (response.status === 201)
        setInviteData({
          name: "",
          email: "",
          designation: "",
        });
        setIsOpen(false);
        alert("Invite sent successfully")

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button className="bg-primary-foreground text-primary px-6 py-2 rounded-sm">
          Add User
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={sendInvite}>
          <DialogHeader>
            <DialogTitle>Add New User</DialogTitle>
            <DialogDescription>Add new user here</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input
                id="name"
                className="col-span-3"
                required
                onChange={(e) =>
                  setInviteData({ ...inviteData, name: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                className="col-span-3"
                required
                onChange={(e) =>
                  setInviteData({ ...inviteData, email: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="designation" className="text-right">
                Designation
              </Label>
              <Input
                id="designation"
                className="col-span-3"
                required
                onChange={(e) =>
                  setInviteData({ ...inviteData, designation: e.target.value })
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Send Invite</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
