import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/logo.png";
import AdminTopbarComponent from "./admin_topbar";
import AdminSidebarComponent from "./admin_sidebar";

function AdminDashboardLayout() {

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col">
          <div className="flex justify-center items-center min-h-16 max-h-16 border-b border-r border-background bg-primary">
            <img src={logo} alt="logo" width={100} height={100} />
          </div>
          <div className="flex-1 bg-primary">
            <AdminSidebarComponent />
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="min-h-16 max-h-16 flex items-center border-b border-border bg-primary">
          <AdminTopbarComponent />
        </header>
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default AdminDashboardLayout;
