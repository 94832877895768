import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/layout/dashlayout";
import AdminDashboard from "./pages/admin/dashboard";
import PageNotFoundError from "./pages/404error";
import LoginPage from "./pages/auth/login";
import MyTeamPage from "./pages/admin/myteam";
import EmployeeAnalyticsPage from "./pages/admin/employeeanalytics";
import ScreenshotsPage from "./pages/admin/screenshots";
import EmployeeScreencapturePage from "./pages/admin/employeescreencaptures";
import ManageTeamPage from "./pages/admin/manageteam";
import GetUserReportPage from "./pages/admin/reports";
import ManageInvitesPage from "./pages/admin/manageinvites";
import UserProfilePage from "./pages/admin/userprofile";
import EmployeeProfilePage from "./pages/admin/employeeprofile";
import UserOverviewPage from "./pages/user/overview";
import RegisterPage from "./pages/auth/register";
import RegisterEmployeePage from "./pages/auth/emp_signup";
import ForgotPasswordPage from "./pages/auth/forgotpwd";
import UserDashboardLayout from "./components/layout/userlayout";
import CompanyProfilePage from "./pages/admin/settings";
import ApplyLeavePage from "./pages/admin/applyleave";
import ManageLeaveApplicationsPage from "./pages/admin/manageleave";
import ViewLeaveApplicationPage from "./pages/admin/viewleaveapplication";
import LeaveApplicationPage from "./pages/user/leaveapplication";
import {
  AdminProtectedRoutes,
  UserProtectedRoutes,
} from "./components/context/protectedroute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFoundError />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/register/:invite" element={<RegisterEmployeePage />} />

        {/* Admin Routes */}
        <Route element={<AdminProtectedRoutes />}>
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/myteam" element={<MyTeamPage />} />
            <Route
              path="/employeeanalytics/:id"
              element={<EmployeeAnalyticsPage />}
            />
            <Route path="/screenshots" element={<ScreenshotsPage />} />
            <Route
              path="/screenshots/:id"
              element={<EmployeeScreencapturePage />}
            />
            <Route path="reports" element={<GetUserReportPage />} />
            <Route path="/manageteam" element={<ManageTeamPage />} />
            <Route path="/manageinvites" element={<ManageInvitesPage />} />
            <Route path="/userprofile" element={<UserProfilePage />} />
            <Route
              path="/employeeprofile/:id"
              element={<EmployeeProfilePage />}
            />
            <Route path="/applyleave" element={<ApplyLeavePage />} />
            <Route
              path="/manageleave"
              element={<ManageLeaveApplicationsPage />}
            />
            <Route
              path="/viewleaveapplication/:id"
              element={<ViewLeaveApplicationPage />}
            />
            <Route path="/settings" element={<CompanyProfilePage />} />
            <Route path="/billing" element={<p>Billing</p>} />
          </Route>
        </Route>

        {/* User Routes */}
        <Route element={<UserProtectedRoutes />}>
          <Route element={<UserDashboardLayout />}>
            <Route path="/overview" element={<UserOverviewPage />} />
            <Route
              path="/leaveapplication"
              element={<LeaveApplicationPage />}
            />
            <Route path="/myprofile" element={<UserProfilePage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
