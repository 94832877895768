import React, { useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import BeatLoader from "react-spinners/BeatLoader";
import { MdPhotoCamera } from "react-icons/md";
import axiosInstance from "../../lib/apiconfig";

export default function UpdateCompanyLogo() {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadCompanyLogo, setUploadCompanyLogo] = useState({
    logo: null,
  });

  const uploadPhoto = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("logo", uploadCompanyLogo);

      const response = await axiosInstance.patch(
        "upload/companylogo/",
        formData
      );
      if (response.status === 201) {
        setIsOpen(false);
        alert("Photo uploaded successfully");
      }
    } catch (error) {
      alert("Error uploading photo, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <button className="-ml-3">
          <MdPhotoCamera />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={uploadPhoto}>
          <DialogHeader>
            <DialogTitle>Update Company Logo</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Input
                id="photo"
                type="file"
                className="col-span-4"
                required
                accept=".jpg, .jpeg, .png"
                onChange={(e) => setUploadCompanyLogo(e.target.files[0])}
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">{loading ? <BeatLoader className="text-white text-xs" /> : "Update"}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
