import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import AddUserAccount from "../elements/adduser";
import axiosInstance from "../../lib/apiconfig";
import Cookies from "js-cookie";

function AdminTopbarComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const refreshtoken = Cookies.get("refresh");
  const data = {
    refresh_token: refreshtoken,
  };
  const handleSignOut = async () => {
    try {
      const response = await axiosInstance.post("logout/", data);
      if (response.status === 200) {
        Cookies.remove("access");
        Cookies.remove("refresh");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const [profileImage, setProfileImage] = useState({});

  const getProfileImage = async () => {
    try {
      const response = await axiosInstance.get("user/profileimage/");
      if (response.status === 200) {
        setProfileImage(response.data);
      }
    } catch (error) {
      console.error("Error getting profile image:", error);
    }
  };

  const [getNotification, setGetNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const getNotificationData = async () => {
    try {
      const response = await axiosInstance.get("notifications/");
      if (response.status === 200) {
        const unreadNotifications = response.data.filter(
          (notification) => notification.is_read === false
        );
        setGetNotification(unreadNotifications);
        setNotificationCount(unreadNotifications.length);
      }
    } catch (error) {
      console.error("Error getting notification data:", error);
    }
  };

  const handleNotification = async (id, event, user) => {
    if (event === "profile_update") {
      navigate(`/employeeprofile/${user}`);
    } else if (event === "leave_application") {
      navigate("/manageleave");
    }
    try {
      const response = await axiosInstance.patch(`update/notification/${id}`);
      if (response.status === 200) {
        getNotificationData();
      }
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  useEffect(() => {
    getProfileImage();
    getNotificationData();
  }, []);

  useEffect(() => {
    getNotificationData();
  }, [location]);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center px-6">
        <div></div>
        <div className="flex justify-between items-center gap-4 mr-1">
          <a
            href="https://api.taskplus.io/api/v1/download/desktopapp/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-blue-300 text-primary px-6 py-2 rounded-sm">
              Download App
            </button>
          </a>
          <AddUserAccount />

          {/* Notification dropdown */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div>
                <Button
                  variant="outline"
                  size="icon"
                  className="overflow-hidden rounded-full"
                >
                  <FaBell />
                </Button>
                {notificationCount > 0 && (
                  <span className="inline-block absolute bg-red-500 rounded-full min-w-2 min-h-2 max-w-2 max-h-2" />
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-52">
              {getNotification.map((notification) => (
                <div
                  key={notification.id}
                  className="flex justify-between items-center gap-2"
                >
                  <DropdownMenuItem>
                    <button
                      onClick={() => {
                        handleNotification(
                          notification.id,
                          notification.event_type,
                          notification.sender_uuid
                        );
                      }}
                      className="text-left"
                    >
                      {notification.message}
                    </button>
                  </DropdownMenuItem>
                </div>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          {/* User dropdown */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="overflow-hidden rounded-full"
              >
                <span
                  className={
                    profileImage.profile_image ? "" : "text-2xl text-primary"
                  }
                >
                  {profileImage.photo ? (
                    <img
                      src={`https://api.taskplus.io${profileImage.photo}`}
                      alt="profile"
                      className="w-8 h-8 rounded-full"
                    />
                  ) : (
                    profileImage.first_name?.charAt(0)
                  )}
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-36">
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Link to="/settings">
                <DropdownMenuItem className="cursor-pointer">
                  Settings
                </DropdownMenuItem>
              </Link>
              <DropdownMenuItem>Support</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <button onClick={handleSignOut}>Logout</button>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}

export default AdminTopbarComponent;
