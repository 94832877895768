import React, { useEffect, useState } from "react";
// import { Card } from "@/components/ui/card";
import axiosInstance from "../../lib/apiconfig";
import MyDailyWorkReport from "../../components/elements/myworkreport";
import TopbarComponent from "../../components/layout/topbar";

function UserOverviewPage() {
  const [userData, setUserData] = useState({});
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get(`data/user/`);
      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="flex justify-between items-center">
          <div className="">
            <h1 className="text-2xl font-semibold">
              {userData.first_name} {userData.last_name}
            </h1>
            <p className="text-base">{userData.designation}</p>
          </div>
        </div>
        <MyDailyWorkReport />
      </div>
  );
}

export default UserOverviewPage;
