import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

import axiosInstance from "../../lib/apiconfig";
import { Button } from "../../components/ui/button";

function ManageLeaveApplicationsPage() {
  const [leaveApplications, setLeaveApplications] = useState([
    {
      uuid: "",
      leave_subject: "",
      start_date: "",
      end_date: "",
      user: {
        first_name: "",
        last_name: "",
        email: "",
        designation: "",
      },
    },
  ]);

  const STATUS_COLORS = {
    approved: "bg-green-800",
    rejected: "bg-red-700",
    pending: "bg-gray-600",
  };

  const fetchLeaveApplications = async () => {
    try {
      const response = await axiosInstance.get(
        "company/list/leaveapplications/"
      );
      setLeaveApplications(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchLeaveApplications();
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
      <div className="w-full">
        <div className="flex items-center py-4">
          <h2 className="text-2xl font-bold">Leave Applications</h2>
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Designation</TableHead>
                <TableHead>Leave Subject</TableHead>
                <TableHead>From Date</TableHead>
                <TableHead>Till Date</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Application</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {leaveApplications.map((application, index) => (
                <TableRow key={index}>
                  <TableCell>{application.user?.first_name}</TableCell>
                  <TableCell>{application.user?.email}</TableCell>
                  <TableCell>{application.user?.designation}</TableCell>
                  <TableCell>{application.leave_subject}</TableCell>
                  <TableCell>{application.start_date}</TableCell>
                  <TableCell>{application.end_date}</TableCell>
                  <TableCell>
                    <span
                      className={`${
                        STATUS_COLORS[application.status]
                      } px-3 py-1 text-white uppercase`}
                    >
                      {application.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Link to={`/viewleaveapplication/${application.uuid}`}>
                      <Button>View</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ManageLeaveApplicationsPage;
