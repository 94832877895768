import React, { useState, useEffect } from "react";
import axiosInstance from "../../lib/apiconfig";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";

import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { MdMoreHoriz } from "react-icons/md";
import { MdSearch } from "react-icons/md";
import AdminTopbarComponent from "../../components/layout/admin_topbar";

function ManageTeamPage() {
  const [teamMembers, setTeamMembers] = useState([]);

  const fetchTeamMembers = async () => {
    try {
      const response = await axiosInstance.get("list/employees/");
      if (response.status === 200) {
        setTeamMembers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteTeamMember = async (uuid) => {
    try {
      const response = await axiosInstance.delete(
        `delete/user/employeeaccount/${uuid}/`
      );
      if (response.status === 200) {
        alert("Employee deleted successfully");
        fetchTeamMembers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);
  return (
    <>
      <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
        <div className="w-full">
        <div className="flex items-center py-4">
            <h2 className="text-2xl font-bold">Manage Team</h2>
          </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Designation</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {teamMembers.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item.first_name} {item.last_name}
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.designation}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MdMoreHoriz className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuItem>
                            <Link to={`/employeeprofile/${item.uuid}`}>
                              View Profile
                            </Link>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem className="text-red-800">
                            <button onClick={() => deleteTeamMember(item.uuid)}>
                              Delete Account
                            </button>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTeamPage;
