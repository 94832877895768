import React from "react";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import BeatLoader from "react-spinners/BeatLoader";
import logo from "../../assets/logo.png";


function ForgotPasswordPage() {
  const loading = false;
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="-mt-16 flex justify-center items-center">
        <img src={logo} alt="logo" width={180} height={180} />
      </div>
      <Card className="mx-auto w-1/4">
        <CardHeader>
          <CardTitle className="text-2xl">Forgot Password?</CardTitle>
          <CardDescription>
            Enter your email below to get a password reset link
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form method="post">
            <div className="grid gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  required
                />
              </div>
              <Button type="submit" className="w-full">
              {loading ? <BeatLoader size={8} color="#ffffff" /> : "Submit"}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ForgotPasswordPage;
