import React, { useEffect, useState } from "react";
import axiosInstance from "../../lib/apiconfig";
import { Card } from "../../components/ui/card";
import AdminTopbarComponent from "../../components/layout/admin_topbar";

function AdminDashboard() {
  const [userCount, setUserCount] = useState(0);
  const [workingCount, setWorkingCount] = useState(0);
  const [onBreakCount, setOnBreakCount] = useState(0);
  const [onLeaveCount, setOnLeaveCount] = useState(0);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`count/employees/`);
      const response2 = await axiosInstance.get(`count/employees/working`);
      const response3 = await axiosInstance.get(`count/employees/onbreak`);
      const response4 = await axiosInstance.get(`count/employees/onleave`);
      setUserCount(response.data.emp_count);
      setWorkingCount(response2.data.emp_count);
      setOnBreakCount(response3.data.emp_count);
      setOnLeaveCount(response4.data.emp_count);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <Card className="rounded-lg">
            <div className="px-6 py-4">
              <h2 className="pb-1">Total Team Members</h2>
              <span className="text-3xl font-semibold">{userCount}</span>
            </div>
          </Card>
          <Card className="rounded-lg">
            <div className="px-6 py-4">
              <h2 className="pb-1">Currently Working</h2>
              <span className="text-3xl font-semibold">{workingCount}</span>
            </div>
          </Card>
          <Card className="rounded-lg">
            <div className="px-6 py-4">
              <h2 className="pb-1">Currently On Break</h2>
              <span className="text-3xl font-semibold">{onBreakCount}</span>
            </div>
          </Card>
          <Card className="rounded-lg">
            <div className="px-6 py-4">
              <h2 className="pb-1">Not Working</h2>
              <span className="text-3xl font-semibold">{onLeaveCount}</span>
            </div>
          </Card>
        </div>
      </div>
  );
}

export default AdminDashboard;
