import React, { useState, useEffect } from "react";
import axiosInstance from "../../lib/apiconfig";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { MdSearch } from "react-icons/md";
import AdminTopbarComponent from "../../components/layout/admin_topbar";

function GetUserReportPage() {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axiosInstance.get("list/employees/");
        if (response.status === 200) {
          setTeamMembers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchTeamMembers();
  }, []);
  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
        <div className="w-full">
          <div className="flex items-center py-4">
            <h2 className="text-2xl font-bold">Employee Reports</h2>
          </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Sr.</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Designation</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {teamMembers.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {item.first_name} {item.last_name}
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.designation}</TableCell>
                    <TableCell>
                      <Link to={`/employeeanalytics/${item.uuid}`}>
                        <Button>View Report</Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
  );
}

export default GetUserReportPage;
