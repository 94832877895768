import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../components/ui/table";
import axiosInstance from "../../lib/apiconfig";
import UpdateUserPhoto from "../../components/elements/updatephoto";

function UserProfilePage() {
  const formRef = useRef(null);
  const [userProfile, setUserProfile] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    bank_name: "",
    bank_brach_code: "",
    bank_account_title: "",
    bank_account_number: "",
    bank_account_iban: "",
    bank_account_swift_code: "",
    company: {
      name: "",
    },
    photo: "",
  });

  const [userDocuments, setUserDocuments] = useState([
    {
      document: "",
    },
  ]);

  const [uploadUserDocument, setUploadUserDocument] = useState({
    document: null,
  });

  const [userPassword, setUserPassword] = useState({
    current_password: "",
    password: "",
    password2: "",
  });

  const getUserProfile = async () => {
    try {
      const response = await axiosInstance.get("data/user/");
      setUserProfile(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserDocuments = async () => {
    try {
      const response = await axiosInstance.get("user/list/documents/");
      setUserDocuments(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserProfile = async () => {
    try {
      const response = await axiosInstance.patch("update/user/", {
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        phone: userProfile.phone,
        address: userProfile.address,
      });
      if (response.status === 200) {
        alert("Profile updated successfully");
        sendNotification();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateBankDetails = async () => {
    try {
      const response = await axiosInstance.patch(
        "update/user/accountdetails/",
        {
          bank_name: userProfile.bank_name,
          bank_brach_code: userProfile.bank_brach_code,
          bank_account_title: userProfile.bank_account_title,
          bank_account_number: userProfile.bank_account_number,
          bank_account_iban: userProfile.bank_account_iban,
          bank_account_swift_code: userProfile.bank_account_swift_code,
        }
      );
      if (response.status === 200) {
        alert("Bank details updated successfully");
        sendNotification();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadDocument = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("document", uploadUserDocument);

      const response = await axiosInstance.post(
        "user/upload/document/",
        formData
      );
      if (response.status === 201) {
        alert("Document uploaded successfully");
        formRef.current.reset();
        sendNotification();
        getUserDocuments();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePassword = async () => {
    try {
      const response = await axiosInstance.put(
        "update/user/password/",
        userPassword
      );
      if (response.status === 200) {
        alert("Password updated successfully");
        setUserPassword({
          current_password: "",
          password: "",
          password2: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotification = async () => {
    try {
      const response = await axiosInstance.post("create/notification/user/", {
        event_type: "profile_update",
      });
      if (response.status === 200 || response.status === 201) {
        getUserProfile();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserProfile();
    getUserDocuments();
  }, []);

  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-4 p-4 md:p-8">
      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">Your Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex justify-start items-end">
                {userProfile.photo ? (
                  <img
                    src={`https://api.taskplus.io${userProfile.photo}`}
                    alt="user"
                    className="border border-border rounded-full w-[100px] h-[100px]"
                    width={100}
                    height={100}
                  />
                ) : (
                  <span className="w-[100px] h-[100px] rounded-full border border-border flex justify-center items-center text-6xl font-bold">
                    {userProfile.first_name?.charAt(0)}
                  </span>
                )}
                <UpdateUserPhoto />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first-name">First name</Label>
                <Input
                  id="first_name"
                  defaultValue={userProfile.first_name}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last-name">Last name</Label>
                <Input
                  id="last_name"
                  defaultValue={userProfile.last_name}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input id="email" defaultValue={userProfile.email} readOnly />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="phone">Phone</Label>
                <Input
                  id="phone"
                  defaultValue={userProfile.phone}
                  onChange={(e) =>
                    setUserProfile({ ...userProfile, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="address">Address</Label>
              <Input
                id="address"
                defaultValue={userProfile.address}
                onChange={(e) =>
                  setUserProfile({ ...userProfile, address: e.target.value })
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="company">Company</Label>
                <Input
                  id="company"
                  defaultValue={userProfile.company.name}
                  readOnly
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="designation">Designation</Label>
                <Input
                  id="designation"
                  defaultValue={userProfile.designation}
                  readOnly
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <button
            className="px-6 py-2 rounded bg-primary text-primary-foreground shadow"
            onClick={updateUserProfile}
          >
            Update
          </button>
        </CardFooter>
      </Card>

      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">Bank Details</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="bank-name">Bank Name</Label>
                <Input
                  id="bank_name"
                  defaultValue={userProfile.bank_name}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="branch-code">Branch Code</Label>
                <Input
                  id="branch_code"
                  defaultValue={userProfile.bank_brach_code}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_brach_code: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="account-title">Account Title</Label>
                <Input
                  id="account_title"
                  defaultValue={userProfile.bank_account_title}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_account_title: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="account-number">Account Number</Label>
                <Input
                  id="account_number"
                  defaultValue={userProfile.bank_account_number}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_account_number: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="iban-code">IBAN</Label>
                <Input
                  id="iban_code"
                  defaultValue={userProfile.bank_account_iban}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_account_iban: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="swift-code">SWIFT Code</Label>
                <Input
                  id="swift_code"
                  defaultValue={userProfile.bank_account_swift_code}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      bank_account_swift_code: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <button
            className="px-6 py-2 rounded bg-primary text-primary-foreground shadow"
            onClick={updateBankDetails}
          >
            Update
          </button>
        </CardFooter>
      </Card>

      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">
            Company Agreements
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableBody className="border border-border">
              {userDocuments.map((document, index) => (
                <TableRow key={index}>
                  <TableCell className="w-3/4">
                    {document.document.split("/")[4]}
                  </TableCell>
                  <TableCell className="text-right w-1/4">
                    <button className="bg-primary/25 text-primary font-medium hadow px-6 py-2 rounded-sm hover:bg-primary/90 hover:text-white">
                      <a
                        href={`https://api.taskplus.io/api/v1/download/document/${document.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <hr className="border border-primary/40 mt-6" />
          <form className="mt-8" ref={formRef}>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="document-upload">Upload Document</Label>
                <Input
                  id="document-upload"
                  type="file"
                  onChange={(e) => setUploadUserDocument(e.target.files[0])}
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <Button onClick={uploadDocument}>Upload</Button>
        </CardFooter>
      </Card>

      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <CardTitle className="font-medium text-lg">Change Password</CardTitle>
        </CardHeader>
        <CardContent>
          <form ref={formRef}>
            <div className="grid grid-cols-3 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="designation">Current Password</Label>
                <Input
                  id="curr_password"
                  type="password"
                  value={userPassword.current_password}
                  onChange={(e) =>
                    setUserPassword({
                      ...userPassword,
                      current_password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="designation">New Password</Label>
                <Input
                  id="password"
                  type="password"
                  value={userPassword.password}
                  onChange={(e) =>
                    setUserPassword({
                      ...userPassword,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="company">Confirm Password</Label>
                <Input
                  id="password2"
                  type="password"
                  value={userPassword.password2}
                  onChange={(e) =>
                    setUserPassword({
                      ...userPassword,
                      password2: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end">
          <Button onClick={updatePassword}>Update</Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default UserProfilePage;
