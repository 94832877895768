import axios from "axios";
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  // baseURL: "http://localhost:8000/api/v1/",
  baseURL: "https://api.taskplus.io/api/v1/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("access");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
