import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Textarea } from "../../components/ui/textarea";
import { Button } from "../../components/ui/button";
import axiosInstance from "../../lib/apiconfig";
import { useParams } from "react-router-dom";
import { getUserId } from "../../lib/jwtdecode";

function ViewLeaveApplicationPage() {
  const { id } = useParams();
  const user = getUserId();

  const [applicationData, setApplicationData] = useState({
    start_date: "",
    end_date: "",
    leave_subject: "",
    reason: "",
    user: {
      first_name: "",
      last_name: "",
      email: "",
      designation: "",
    },
    status: "",
    company_note: "",
  });
  const STATUS_COLORS = {
    approved: "bg-green-800",
    rejected: "bg-red-700",
    pending: "bg-gray-600",
  };

  const fetchLeaveApplications = async () => {
    try {
      const response = await axiosInstance.get(
        "company/view/leaveapplications/" + id
      );
      setApplicationData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const approveApplication = async () => {
    try {
      const response = await axiosInstance.patch(
        "company/approve/leaveapplication/" + id
      );
      if (response.status === 200) {
        sendNotification();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejectApplication = async () => {
    try {
      const response = await axiosInstance.patch(
        "company/reject/leaveapplication/" + id
      );
      if (response.status === 200) {
        sendNotification();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotification = async () => {
    try {
      const response = await axiosInstance.post("create/notification/admin" + user, {
        event_type: "leave_application",
      });
      if (response.status === 200 || response.status === 201) {
        fetchLeaveApplications();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLeaveApplications();
  }, []);
  return (
    <div className="flex flex-1 flex-col justify-center items-center gap-4 p-4 md:p-8">
      <Card className="rounded-sm w-3/4">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle className="font-medium text-lg">
              Leave Application
            </CardTitle>
            <span
              className={`${
                STATUS_COLORS[applicationData.status]
              } px-6 py-2 text-xl font-medium text-white`}
            >
              {applicationData.status.toUpperCase()}
            </span>
          </div>
        </CardHeader>
        <CardContent>
          <form className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="first_name">First Name</Label>
                <Input
                  id="first_name"
                  readOnly
                  defaultValue={applicationData.user.first_name}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  readOnly
                  defaultValue={applicationData.user.last_name}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  readOnly
                  defaultValue={applicationData.user.email}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="designation">Designation</Label>
                <Input
                  id="designation"
                  readOnly
                  defaultValue={applicationData.user.designation}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="start_date"></Label>
                <Input
                  id="start_date"
                  readOnly
                  defaultValue={applicationData.start_date}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="end_date">End Date</Label>
                <Input
                  id="end_date"
                  readOnly
                  defaultValue={applicationData.end_date}
                />
              </div>
            </div>
            <div className="grid gap-4">
              <Label htmlFor="subject">Leave Subject</Label>
              <Input
                id="subject"
                readOnly
                defaultValue={applicationData.leave_subject}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="reason">Reason</Label>
              <Textarea readOnly defaultValue={applicationData.reason} />
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="reason">Company Note</Label>
              <Textarea defaultValue={applicationData.company_note} />
            </div>
          </form>
        </CardContent>
        <CardFooter className="border-t px-6 py-4 justify-end gap-6">
          <Button onClick={rejectApplication} className="bg-red-600">
            Reject Application
          </Button>
          <Button onClick={approveApplication} className="bg-green-600">
            Approve Application
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ViewLeaveApplicationPage;
