import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import ApplyLeaveApplication from "../../components/elements/applyleaveapplication";
import axiosInstance from "../../lib/apiconfig";
import TopbarComponent from "../../components/layout/topbar";

function LeaveApplicationPage() {
  const [leaveApplications, setLeaveApplications] = useState([]);

  const STATUS_COLORS = {
    approved: "bg-green-800",
    rejected: "bg-red-700",
    pending: "bg-gray-600",
  };

  useEffect(() => {
    try {
      const fetchLeaveApplications = async () => {
        const response = await axiosInstance.get("list/leaveapplications/");
        setLeaveApplications(response.data);
      };
      fetchLeaveApplications();
    } catch (error) {
      console.error(error);
    }
  }, []);
  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
      <div className="w-full">
        <div className="flex items-center justify-between py-4">
          <h2 className="text-2xl font-bold">Leave Applications</h2>
          <ApplyLeaveApplication />
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>Leave Subject</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {leaveApplications.map((application, index) => (
                <TableRow key={index}>
                  <TableCell>{application.start_date}</TableCell>
                  <TableCell>{application.end_date}</TableCell>
                  <TableCell>{application.leave_subject}</TableCell>
                  <TableCell>
                    <span
                      className={`${
                        STATUS_COLORS[application.status]
                      } px-3 py-1 text-white uppercase`}
                    >
                      {application.status}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default LeaveApplicationPage;
