import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../lib/apiconfig";
import { Card } from "../../components/ui/card";
import AdminTopbarComponent from "../../components/layout/admin_topbar";

function MyTeamPage() {
  const [teamMembers, setTeamMembers] = useState([
    {
      uuid: "",
      first_name: "",
      last_name: "",
      designation: "",
      workpresence_status: "",
      workpresence: [
        {
          check_in: "",
          check_out: "",
        },
      ],
    },
  ]);

  const STATUS_COLORS = {
    online: "bg-green-300",
    on_break: "bg-yellow-300",
    offline: "bg-gray-300",
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`list/employees/`);
      setTeamMembers(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const formattedTime = (time) => {
    const dateObj = new Date(time);
    const newtime = dateObj.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
    });
    return newtime;
  };

  const formattedDate = (time) => {
    const dateObj = new Date(time);
    const newtime = dateObj.toLocaleDateString("en-IN", {
      timeZone: "UTC",
    });
    return newtime;
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          {teamMembers.map((member, index) => (
            <Link to={`/employeeanalytics/${member.uuid}`} key={index}>
              <Card className="p-4">
                <div className="flex justify-between items-center">
                  <div className="text-left">
                    <h2 className="pb-1 font-semibold">
                      {member.first_name} {member.last_name}
                    </h2>
                    <p className="text-xs">{member.designation}</p>
                  </div>
                  <span
                    className={`text-sm py-1 px-2 rounded ${
                      STATUS_COLORS[member.workpresence_status]
                    }`}
                  >
                    {member.workpresence_status
                      ? member.workpresence_status.toUpperCase()
                      : "--"}
                  </span>
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div className="text-left">
                    <span className="font-semibold">
                      {member.workpresence[member.workpresence.length - 1]
                        ?.check_in
                        ? formattedTime(
                            member.workpresence[member.workpresence.length - 1]
                              .check_in
                          )
                        : "--:--"}
                    </span>
                    <p className="text-xs">Checked In</p>
                  </div>
                  <div className="text-center">
                    <span className="font-semibold text-sm">
                      {member.workpresence[member.workpresence.length - 1]
                        ?.check_in
                        ? formattedDate(
                            member.workpresence[member.workpresence.length - 1]
                              .check_in
                          )
                        : "--:--"}
                    </span>
                    <p className="text-xs">Date</p>
                  </div>
                  <div className="text-right">
                    <span className="font-semibold">
                      {member.workpresence[member.workpresence.length - 1]
                        ?.check_out
                        ? formattedTime(
                            member.workpresence[member.workpresence.length - 1]
                              .check_out
                          )
                        : "--:--"}
                    </span>
                    <p className="text-xs">Checked Out</p>
                  </div>
                </div>
              </Card>
            </Link>
          ))}
        </div>
      </div>
  );
}

export default MyTeamPage;
