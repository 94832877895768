import React, { useState, useEffect } from "react";
import { Card } from "../../components/ui/card";
import one from "../../assets/screenshots/photos-icon.png";
import { Link } from "react-router-dom";
import axiosInstance from "../../lib/apiconfig";

function ScreenshotsPage() {
  const [teamMembers, setTeamMembers] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`list/employees/`);
      setTeamMembers(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {teamMembers.map((member, index) => (
              <Link to={member.uuid} key={index}>
                <Card className="rounded-[2px] p-2">
                  <div className="mb-2">
                    <p className="text-lg font-medium">
                      {member.first_name} {member.last_name}
                    </p>
                  </div>
                  <hr className="border-foreground my-3" />
                  <img
                    src={one}
                    width={250}
                    height={250}
                    alt="screenshot1"
                  ></img>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </div>
  );
}

export default ScreenshotsPage;
