import React from "react";
import { Link } from "react-router-dom";
import {
  SIDEBAR_NAV_REALTIME,
  SIDEBAR_NAV_WORKPROOF,
  SIDEBAR_NAV_LEAVEMNGT,
  SIDEBAR_NAV_EMPLOYEEMANGT,
  SIDEBAR_NAV_SETTINGS,
} from "../../lib/menu";

function AdminSidebarComponent() {
  return (
    <div>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Realtime
        </h2>
        {SIDEBAR_NAV_REALTIME.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Proof of work
        </h2>
        {SIDEBAR_NAV_WORKPROOF.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Leave Management
        </h2>
        {SIDEBAR_NAV_LEAVEMNGT.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Team Management
        </h2>
        {SIDEBAR_NAV_EMPLOYEEMANGT.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Configuration
        </h2>
        {SIDEBAR_NAV_SETTINGS.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminSidebarComponent;
