import { MdDashboard } from "react-icons/md";
import { FaUsersLine } from "react-icons/fa6";
import { FaRegImages } from "react-icons/fa";
import { AiOutlineHistory } from "react-icons/ai";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { MdRateReview } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { MdOutlinePayment } from "react-icons/md";

export const SIDEBAR_NAV_REALTIME = [
    {
      title: "Overview",
      href: "/dashboard",
      icon: <MdDashboard />,
    },
    {
      title: "My Team",
      href: "/myteam",
      icon: <FaUsersLine />,
    },
  ];
  export const SIDEBAR_NAV_WORKPROOF = [
    {
      title: "Screenshots",
      href: "/screenshots",
      icon: <FaRegImages />,
    },
    {
      title: "Reports",
      href: "/reports",
      icon: <AiOutlineHistory />,
    },
  ];
  
  export const SIDEBAR_NAV_LEAVEMNGT = [
    {
      title: "Apply Leave",
      href: "/applyleave",
      icon: <FaRegCalendarAlt />,
    },
    {
      title: "Manage Leave",
      href: "/manageleave",
      icon: <FaRegCalendarCheck />,
    },
  ];
  
  export const SIDEBAR_NAV_APPUSE = [
    {
      title: "Review Apps",
      href: "/reviewapps",
      icon: <MdRateReview />,
    },
    {
      title: "App History",
      href: "/apphistory",
      icon: <FaUsersLine />,
    },
    {
      title: "App Summary",
      href: "/appsummary",
      icon: <FaUsersLine />,
    },
  ];
  export const SIDEBAR_NAV_EMPLOYEEMANGT = [
    {
      title: "Manage Team",
      href: "/manageteam",
      icon: <FaUsersLine />,
    },
    {
      title: "Manage Invites",
      href: "/manageinvites",
      icon: <FaUsersLine />,
    },
  ];
  
  export const SIDEBAR_NAV_SETTINGS = [
    {
      title: "User Profile",
      href: "/userprofile",
      icon: <FaUserCog />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <IoMdSettings />,
    },
    {
      title: "Billing",
      href: "/billing",
      icon: <MdOutlinePayment />,
    },
  ];
  

  export const SIDEBAR_NAV_USER = [
    {
      title: "Overview",
      href: "/overview",
      icon: <MdDashboard />,
    },
    {
      title: "Apply Leave",
      href: "/leaveapplication",
      icon: <FaRegCalendarAlt />,
    },
    {
      title: "Profile",
      href: "/myprofile",
      icon: <FaUserCog />,
    },
  ];