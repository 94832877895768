import React, { useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "../ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "../../lib/utils";
import { Calendar } from "../ui/calendar";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import { Input } from "../ui/input";

import axiosInstance from "../../lib/apiconfig";

export default function ApplyLeaveApplication() {
  const [applicationData, setApplicationData] = useState({
    start_date: null,
    end_date: null,
    reason: "",
    leave_subject: "",
  });

  const sendApplication = async (e) => {
    e.preventDefault();
    try {
      const start_date = new Date(applicationData.start_date);
      const end_date = new Date(applicationData.end_date);
      const formated_start_date = `${String(start_date.getFullYear())}-${String(
        start_date.getMonth() + 1
      )}-${String(start_date.getDate())}`;
      const formated_end_date = `${String(end_date.getFullYear())}-${String(
        end_date.getMonth() + 1
      )}-${String(end_date.getDate())}`;

      const response = await axiosInstance.post("apply/leaveapplication/", {
        start_date: formated_start_date,
        end_date: formated_end_date,
        reason: applicationData.reason,
        leave_subject: applicationData.leave_subject,
      });
      if (response.status === 201) {
        alert("Leave application sent successfully");
        sendNotification();
        setApplicationData({
          start_date: null,
          end_date: null,
          reason: "",
          leave_type: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNotification = async () => {
    try {
      await axiosInstance.post("create/notification/user/", {
        event_type: "leave_application",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="bg-foreground text-background px-6 py-2 rounded-sm">
          Apply Leave
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[720px]">
        <form onSubmit={sendApplication}>
          <DialogHeader>
            <DialogTitle>Apply Leave Application</DialogTitle>
            <DialogDescription>
              Please fill the form to apply for leave application
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="date">Start Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !applicationData.start_date && "text-muted-foreground"
                      )}
                    >
                      <FaCalendar className="mr-2 h-4 w-4" />
                      {applicationData.start_date ? (
                        format(applicationData.start_date, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={applicationData.start_date}
                      onSelect={(date) => {
                        setApplicationData({
                          ...applicationData,
                          start_date: date,
                        });
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="date">End Date</Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !applicationData.end_date && "text-muted-foreground"
                      )}
                    >
                      <FaCalendar className="mr-2 h-4 w-4" />
                      {applicationData.end_date ? (
                        format(applicationData.end_date, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={applicationData.end_date}
                      onSelect={(date) => {
                        setApplicationData({
                          ...applicationData,
                          end_date: date,
                        });
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Leave Subject</Label>
                <Input
                  type="text"
                  required
                  maxLength="100"
                  value={applicationData.leave_subject}
                  onChange={(e) =>
                    setApplicationData({
                      ...applicationData,
                      leave_subject: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Label htmlFor="first-name">Reason</Label>
              <Textarea
                required
                onChange={(e) =>
                  setApplicationData({
                    ...applicationData,
                    reason: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Submit Application</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
