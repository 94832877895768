import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { Card } from "@/components/ui/card";
import axiosInstance from "../../lib/apiconfig";
import EmployeeDailyWorkReport from "../../components/elements/employeereport";
import CreateDailyWorkingPDFReport from "../../components/elements/createpdfreport";

function EmployeeAnalyticsPage() {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const fetchUserData = async () => {
    try {
      const response = await axiosInstance.get(`data/user/employee/${id}`);
      setUserData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
      <div className="flex justify-between items-center">
        <div className="">
          <h1 className="text-2xl font-semibold">
            {userData.first_name} {userData.last_name}
          </h1>
          <p className="text-base">{userData.designation}</p>
        </div>
        <div className="flex gap-4">
          <CreateDailyWorkingPDFReport />
          <Link to={`/employeeprofile/${id}`}>
            <button className="bg-foreground px-8 py-2 text-white rounded-sm">
              View Profile
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
        <Card className="rounded-lg">
          <div className="px-6 py-4">
            <h2 className="pb-1 font-medium">Active Time</h2>
            <span className="text-3xl mt-3 font-semibold">07h 11m</span>
          </div>
        </Card>
        <Card className="rounded-lg">
          <div className="px-6 py-4">
            <h2 className="pb-1 font-medium">Productive Apps %</h2>
            <span className="text-3xl mt-3 font-semibold">3%</span>
          </div>
        </Card>
        <Card className="rounded-lg">
          <div className="px-6 py-4">
            <h2 className="pb-1 font-medium">Idle Time Spent %</h2>
            <span className="text-3xl mt-3 font-semibold">3%</span>
          </div>
        </Card>
        <Card className="rounded-lg">
          <div className="px-6 py-4">
            <h2 className="pb-1 font-medium">Total Worked Hours</h2>
            <span className="text-3xl mt-3 font-semibold">3%</span>
          </div>
        </Card>
      </div> */}
      <div>
        <h2 className="text-2xl font-semibold">Working Report</h2>
        <div className="py-2"></div>
        <EmployeeDailyWorkReport />
      </div>
    </div>
  );
}

export default EmployeeAnalyticsPage;
