import React from "react";
import { Link } from "react-router-dom";
import { SIDEBAR_NAV_USER } from "../../lib/menu";

function UserSidebarComponent() {
  return (
    <div>
      <ul>
        <h2 className="text-sm font-medium mt-2 px-3 text-blue-300">
          Dashboard
        </h2>
        {SIDEBAR_NAV_USER.map((item, index) => (
          <li key={index}>
            <Link to={item.href}>
              <button className="flex gap-2 px-6 py-2 w-full text-left text-sm font-medium text-primary-foreground hover:bg-blue-300 hover:text-primary">
                <span className="text-xl">{item.icon}</span>
                <span>{item.title}</span>
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UserSidebarComponent;
