import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "../../components/ui/card";
import { SlCalender } from "react-icons/sl";
import { CiClock2 } from "react-icons/ci";
import axiosInstance from "../../lib/apiconfig";
import { useParams } from "react-router-dom";
import AdminTopbarComponent from "../../components/layout/admin_topbar";

function EmployeeScreencapturePage() {
  const { id } = useParams();
  const [screencaptures, setScreencaptures] = useState([]);
  const [user, setUser] = useState({});

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `data/user/screencaptures/${id}`
      );
      setScreencaptures(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get(`data/user/employee/${id}/`);
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const formatDate = (time) => {
    const date = new Date(time);
    return date.toLocaleDateString("en-IN");
  };

  const formatTime = (time) => {
    const date = new Date(time);
    return date.toLocaleTimeString("en-US");
  };

  const localURI = "https://api.taskplus.io";

  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);

  return (

      <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
        <div>
          <h2 className="font-medium text-2xl mb-9">
            {user.first_name} {user.last_name}
          </h2>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {screencaptures
              .slice()
              .reverse()
              .map((screencapture, index) => (
                <Link to={localURI + screencapture.image} target="_blank">
                  <Card className="rounded-[2px] p-2" key={index}>
                    <img
                      src={localURI + screencapture.image}
                      width={250}
                      height={250}
                      alt="screenshot1"
                    ></img>
                    <div className="flex justify-between items-center mt-2">
                      <div className="flex justify-center items-center gap-2">
                        <SlCalender size={11} />
                        <p className="text-xs font-semibold">
                          {screencapture.created_at
                            ? formatDate(screencapture.created_at)
                            : "--:--"}
                        </p>
                      </div>
                      <div className="flex justify-center items-center gap-1">
                        <CiClock2 size={11} />
                        <p className="text-xs font-semibold">
                          {screencapture.created_at
                            ? formatTime(screencapture.created_at)
                            : "--:--"}
                        </p>
                      </div>
                    </div>
                  </Card>
                </Link>
              ))}
          </div>
        </div>
      </div>
  );
}

export default EmployeeScreencapturePage;
