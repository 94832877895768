import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import BeatLoader from "react-spinners/BeatLoader";
import logo from "../../assets/logo.png";
import axiosInstance from "../../lib/apiconfig";

function RegisterEmployeePage() {
  const { invite } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    company: "",
    designation: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axiosInstance.post("create/employee/", userData);
      if (response.status === 201) {
        navigate("/login");
      } else {
        alert("An error occurred, please try again.");
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchInviteDetails = async () => {
    try {
      const response = await axiosInstance.get(`invite/detail/${invite}/`);
      if (response.status === 5 || response.status === 4) {
        navigate("/register");
      }
      if (response.status === 200) {
        setUserData({
          ...userData,
          first_name: response.data.name,
          company: response.data.inviter_company,
          designation: response.data.designation,
          email: response.data.email,
        });
      } else {
        alert("An error occurred, please try again.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInviteDetails();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="-mt-16 flex justify-center items-center">
        <img src={logo} alt="logo" width={180} height={180} />
      </div>
      <Card className="mx-auto w-1/3">
        <CardHeader>
          <CardTitle className="text-2xl">Sign up</CardTitle>
          <CardDescription>
            Enter your information to create an account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form method="post" onSubmit={handleSubmit}>
            <div className="grid gap-2">
              <div className="grid grid-cols-2 gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="first_name">First name</Label>
                  <Input
                    id="first_name"
                    required
                    value={userData.first_name}
                    onChange={(e) =>
                      setUserData({ ...userData, first_name: e.target.value })
                    }
                  />
                </div>
                <div className="grid gap-2">
                  <Label htmlFor="last_name">Last name</Label>
                  <Input
                    id="last_name"
                    required
                    value={userData.last_name}
                    onChange={(e) =>
                      setUserData({ ...userData, last_name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input id="email" readOnly value={userData.email} />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  required
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                />
              </div>
              <Button type="submit" className="w-full mt-3">
                {loading ? <BeatLoader size={8} color="#ffffff" /> : "Sign up"}
              </Button>
            </div>
          </form>
          <div className="mt-4 text-center text-sm">
            Already have an account?{" "}
            <Link to="/login" className="underline">
              Sign in
            </Link>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default RegisterEmployeePage;
