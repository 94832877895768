import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../lib/jwtdecode";

export const AdminProtectedRoutes = () => {
  const user = getUser();
  const is_admin = !!user?.admin;

  return is_admin ? <Outlet /> : <Navigate to="/login" replace />;
};

export const UserProtectedRoutes = () => {
  const user = getUser();
  const is_admin = !!user?.admin;
  return user && !is_admin ? <Outlet /> : <Navigate to="/login" replace />;
};
