import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Button } from "../../components/ui/button";
import { MdMoreHoriz } from "react-icons/md";
import axiosInstance from "../../lib/apiconfig";

function ManageInvitesPage() {
  const STATUS_COLORS = {
    accepted: "text-green-700",
    sent: "text-purple-700",
    declined: "text-gray-700",
  };
  const [teamInvites, setTeamInvites] = useState([]);
  const inviteLink = "http://app.taskplus.io/register/";

  const getInvites = async () => {
    try {
      const response = await axiosInstance.get(`list/invites/`);
      setTeamInvites(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const deleteInvite = async (uuid) => {
    try {
      const response = await axiosInstance.delete(`invite/delete/${uuid}/`);
      if (response.status === 200) {
        alert("Invite deleted successfully");
        getInvites();
      }
    } catch (error) {
      console.error("Error deleting invite:", error);
    }
  };

  useEffect(() => {
    getInvites();
  }, []);
  return (
      <div className="flex flex-1 flex-col gap-4 p-4 md:p-8">
        <div className="w-full">
        <div className="flex items-center py-4">
            <h2 className="text-2xl font-bold">Team Invites</h2>
          </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Designation</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {teamInvites.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.designation}</TableCell>
                    <TableCell
                      className={`font-medium ${STATUS_COLORS[item.status]}`}
                    >
                      {item.status.toUpperCase()}
                    </TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MdMoreHoriz className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>Actions</DropdownMenuLabel>
                          <DropdownMenuItem>
                            <button
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  inviteLink + item.uuid
                                )
                              }
                            >
                              Copy Invite Link
                            </button>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Resend Email</DropdownMenuItem>
                          <DropdownMenuItem className="text-red-800">
                            <button onClick={() => deleteInvite(item.uuid)}>
                              Delete Invite
                            </button>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
  );
}

export default ManageInvitesPage;
