import React, { useState, useEffect } from "react";
import axiosInstance from "../../lib/apiconfig";
import { useParams } from "react-router-dom";

function EmployeeDailyWorkReport() {
  const { id } = useParams();
  const [workPresenceData, setWorkPresenceData] = useState([
    {
      check_in: "",
      check_out: "",
      work_break: [
        {
          start_break: "",
          end_break: "",
        },
      ],
    },
  ]);

  const fetchWorkPresenceData = async () => {
    try {
      const response = await axiosInstance.get(
        `useranalytics/workpresense/${id}/`
      );
      setWorkPresenceData(response.data);
      console.log(workPresenceData);
    } catch (error) {
      console.error(error);
    }
  };

  const formattedTime = (time) => {
    const dateObj = new Date(time);
    const newtime = dateObj.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
    });
    return newtime;
  };

  const formattedDate = (time) => {
    const dateObj = new Date(time);
    const newtime = dateObj.toLocaleDateString("en-IN", {
      timeZone: "UTC",
    });
    return newtime;
  };

  const calTimeDiff = (start, end) => {
    const start_time = new Date(start);
    const end_time = new Date(end);
    const diff = end_time - start_time;
    const hours = Math.floor(diff / 1000 / 60 / 60);
    const minutes = Math.floor(diff / 1000 / 60) % 60;
    const seconds = Math.floor(diff / 1000) % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    fetchWorkPresenceData();
  }, []);

  return (
    <div className="relative w-full border border-primary/50 rounded-sm px-6 pt-2 pb-6 overflow-y-auto max-h-[500px]">
      <table className="w-full">
        <thead>
          <tr className="text-center border-b border-primary/50">
            <th className="py-2">Date</th>
            <th className="py-2">Time In</th>
            <th className="py-2">Time Out</th>
            <th className="py-2">Worked Hours</th>
            <th className="py-2">
              <span>Breaks</span>
              <hr className="border-primary/30 w-2/3 mx-auto" />
              <div className="grid grid-cols-3 justify-center items-center font-normal">
                <h4>Start</h4>
                <h4>End</h4>
                <h4>Duration</h4>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {workPresenceData
            .slice()
            .reverse()
            .map((data, index) => (
              <tr
                className="text-center border-b border-primary/40"
                key={index}
              >
                <td className="py-3">{formattedDate(data.check_in)}</td>
                <td className="py-3">
                  {data.check_in ? formattedTime(data.check_in) : "--:--"}
                </td>
                <td className="py-3">
                  {data.check_out ? formattedTime(data.check_out) : "--:--"}
                </td>
                <td className="py-3">
                  {data.check_out
                    ? calTimeDiff(data.check_in, data.check_out)
                    : "--:--"}
                </td>
                <td className="py-3">
                  {data.work_break.map((brk, index) => (
                    <div
                      className="grid grid-cols-3 justify-center items-center"
                      key={index}
                    >
                      <div>
                        {brk.start_break
                          ? formattedTime(brk.start_break)
                          : "--:--"}
                      </div>
                      <div>
                        {brk.end_break ? formattedTime(brk.end_break) : "--:--"}
                      </div>
                      <div>
                        {brk.end_break
                          ? calTimeDiff(brk.start_break, brk.end_break)
                          : "--:--"}
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default EmployeeDailyWorkReport;
